.block-1{
  border: solid 1px #16779580;
  background-color: #141D29;
  color: #fff;
  padding: 30px 35px 30px 35px;
  border-radius: 20px;
  margin: 0px 20px;
  position: relative;
  top: 95px;
  left: -50px;
  z-index: -1;
  width: 340px;
}

.block-2{
  border: solid 1px #16779580;
  background-color: #141D29;
  color: #fff;
  padding: 30px 35px 30px 35px;
  border-radius: 20px;
  margin: 0px 20px;
  position: relative;
  top: 195px;
  left: -50px;
  z-index: -1;
  width: 400px;
}

.block-3{
  border: solid 1px #16779580;
  background-color: #141D29;
  color: #fff;
  padding: 30px 35px 30px 35px;
  border-radius: 20px;
  margin: 0px 20px;
  position: relative;
  top: 630px;
  left: -70px;
  z-index: -1;
  width: 400px;
}

.block-4{
  border: solid 1px #16779580;
  background-color: #141D29;
  color: #fff;
  padding: 30px 35px 30px 35px;
  border-radius: 20px;
  margin: 0px 20px;
  position: relative;
  top: -770px;
  left: -90px;
  z-index: -1;
  width: 360px;
}

.block-5{
  border: solid 1px #16779580;
  background-color: #141D29;
  color: #fff;
  padding: 30px 35px 30px 35px;
  border-radius: 20px;
  margin: 0px 20px;
  position: relative;
  top: -355px;
  left: -230px;
  z-index: -1;
  width: 400px;
}

.block-6{
  border: solid 1px #16779580;
  background-color: #141D29;
  color: #fff;
  padding: 30px 35px 30px 35px;
  border-radius: 20px;
  margin: 0px 20px;
  position: relative;
  top: -204px;
  left: -145px;
  z-index: -1;
  width: 400px;
}


.roadmap-inner-heading{
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
}

.roadmap-tick {
  height: 25px;
  width: 25px;
  margin-top: 5px;
}

.roadmap-text{
  color: #ECEFEF;
  margin-bottom: 8px;
  margin-left: 20px;
}

/* for mobile view */
@media (max-width: 991px) {
  .img-container{
    display: none;
  }
  .block-1, .block-2, .block-3, .block-4, .block-5, .block-6{
    top: 0px;
    left: 0px;
    width: 90%;
    margin-top: 20px;
  } 
}

/* for laptop view */
@media (min-width: 990px) and (max-width: 1200px) {
  .block-1{
    left: -40px;
    width: 320px;
  }
  .block-2{
    top: 170px;
    left: -25px;
  }
  .block-3{
    top: 545px;
    left: -135px;
    width: 380px;
  }
  .block-4 {
    top: -646px;
    left: -36px;
    width: 316px;
  }
  .block-5 {
    top: -310px;
    left: -240px;
    width: 370px;
  }
  .block-6 {
    top: -171px;
    left: -140px;
    width: 370px;
  }

}


.box {
  -webkit-box-shadow: 0 0 7px 0 #b3b3b3;
  -moz-box-shadow: 0 0 7px 0 #b3b3b3;
  box-shadow: 0 0 10px 0 #b3b3b3;
  border-radius: 7px;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 175px;
  height: 100%;
  opacity: 1;
}
section.roadmap .box.left {
  padding-right: 40px;
  text-align: right;
}
section.roadmap .box.right {
  padding-left: 40px;
}
section.roadmap span.heading div {
  font-family: Arial, Helvetica !important;
}

.text-right{
  color:#fff
}



/*Responsive test*/
@media (max-width: 991px) {
  section.roadmap .box {
    margin-top: 30px;
  }
  .height-100{
    height: 0px;
  }

  .height-69{
    height: 0px;
  }
}
@media (min-width: 992px) {
  section.roadmap .box.left:after {
    content: " ";
    width: 80px;
    height: 3px;
    background: #ccc;
    position: absolute;
    top: 50%;
    right: -80px;
    color: #fff;
    text-align: center;
    z-index: 1;
  }
  section.roadmap .box.right:after {
    content: " ";
    width: 70px;
    height: 3px;
    background: #ccc;
    position: absolute;
    top: 50%;
    left: -70px;
    color: #fff;
    text-align: center;
    z-index: 1;
  }
}

@media (min-width: 992px) {
  section.roadmap .box.right.first {
    margin-top: 110px !important;
  }
  section.roadmap .box.right.second {
    top: 45px !important;
  }
  section.roadmap .box.right.third {
    top: 88px !important;
  }
  section.roadmap .box.left.second {
    top: -65px !important;
  }
  section.roadmap .box.left.third {
    top: -20px !important;
  }
  section.roadmap .box.left.fourth {
    top: 24px !important;
  }
}

section.roadmap span.heading {
  font-family: Arial, Helvetica !important;
  font-size: 20px;
  top: -4px;
}
section.roadmap .green {
  color: #09d8ba;
}
section.roadmap .dots {
  width: 50% !important;
}
section.roadmap .dots.pull-right {
  margin-right: 50px !important;
}
section.roadmap .dots.pull-right span {
  position: relative;
  right: -50px !important;
}
section.roadmap .dots.pull-left {
  margin-left: 50px !important;
}
section.roadmap .dots.pull-left span {
  position: relative;
  left: -50px !important;
}
section.roadmap .vertical-line.right {
  border-right: 2px solid #fff;
  margin-right: 0;
  padding-right: 20px;
  margin-top: 10px !important;
}
section.roadmap .vertical-line.left {
  border-left: 2px solid #fff;
  margin-left: 0;
  padding-left: 20px;
  margin-top: 10px !important;
}
section.roadmap .row.left,
section.roadmap .row.right {
  position: relative !important;
}
section.roadmap .milestones span {
  font-family: Arial, Helvetica !important;
}

@media (min-width: 992px) {
  section.roadmap .lines {
    position: absolute;
    left: 50%;
  }
  section.roadmap .lines.second {
    top: 5px;
  }
  section.roadmap .lines.first {
    top: 70px;
  }
  section.roadmap .lines.third {
    top: 48px;
  }
  section.roadmap .lines.fourth {
    top: 93px;
  }
  section.roadmap .roadmap-line {
    background: #ccc;
    width: 7px;
    height: 85px;
    position: relative;
    margin-top: 40px;
  }
  .height-100{
    height: 100px !important;
  }
  
  .height-69{
    height: 69px !important;
  }
  section.roadmap .roadmap-line.higher {
    height: 135px;
  }
  section.roadmap .roadmap-line:after {
    content: "";
    width: 200px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
    left: -6px;
    color: #fff;
    text-align: center;
    z-index: 2;
  }
  section.roadmap .roadmap-line.active {
    background: #0be6d0 !important;
  }
  section.roadmap .roadmap-line.active:after {
    background: #0be6d0 !important;
  }
  section.roadmap .roadmap-line:after {
    background: #ccc !important;
  }
}


